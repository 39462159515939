import { opencart, api } from '../helpers/http'
import {
  SET_FILTER_OPTIONS_PAGE,
  SET_ORDER,
  SET_ORDERS,
  SET_STORE_INFO,
  SET_FILTER_DATE_FROM,
  SET_FILTER_DATE_TO,
  SET_FILTER_OPTIONS,
  SET_FILTER_STATUS,
  SET_BLACKLIST_USERS,
  SET_NEW_ORDERS,
  SET_CUSTOM_FIELDS,
  SET_ORDER_ITEM,
  SET_TOTAL_ORDERS,
  SET_SEARCH_STRING,
  UPDATE_ORDER_STATUS,
  SET_LOADING,
} from '../mutation-types'
import {
  API_ORDERS,
  API_ORDERS_DETAIL,
  API_INIT,
  API_BL_SEARCH,
  API_ORDER_SEARCH,
  API_CUSTOM_FIELDS,
  API_ORDER_ITEMS,
} from '@/api'
import moment from 'moment'
import { DATETIME_FORMAT, fromDB } from '@/helpers/dates'

const highlight = (haystack, niddle) => {
  if (haystack && niddle) {
    const words = niddle.toLowerCase().split(' ')
    haystack = haystack.toLowerCase()
    words.forEach((word) => {
      haystack = haystack.replace(word, `<i class="hl">${word}</i>`)
    })
  }
  return haystack
}

export default {
  namespaced: true,
  state: {
    info: {},
    orders: [],
    order: {},
    blUsers: [],
    params: {
      filter_date_added_from: '',
      filter_date_added_to: '',
      filter_order_status_id: '',
      limit: 25,
      page: 1,
    },
    customfields: {},
    total: 0,
    searchString: '',
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
    searchString: (state) => state.searchString,
    orders: (state) => state.orders,
    totalOrders: (state) => state.total,
    orderStatusesGrouped: ({ info }) => {
      if (!info.order_statuses) return []

      const groups = [
        {
          name: 'Default',
          statuses: [
            'New Order (slot problem)',
            'New Order',
            'In Progress',
            'Claim',
            'Refunded',
            'Disputed',
            'Suspicious',
          ],
        },
        {
          name: 'Problem',
          statuses: [
            'Claim',
            'Refunded',
            'Disputed',
            'Pending Payment',
            'New Order (slot problem)',
          ],
        },
        {
          name: 'Completed',
          statuses: ['Completed'],
        },
      ]

      return groups.map(({ name, statuses }) => {
        const statusesIds = info.order_statuses
          .filter((status) => statuses.indexOf(status.name) > -1)
          .map((status) => status.order_status_id)
          .filter((statusId, key, self) => self.indexOf(statusId) === key)

        return {
          text: name,
          value: statusesIds.join(','),
        }
      })
    },
    orderStatuses: ({ info }) => {
      let statuses = []
      if (info.order_statuses) {
        for (let status of info.order_statuses) {
          statuses.push({
            text: status.name,
            value: parseInt(status.order_status_id),
          })
        }
      }
      return statuses
    },
    formattedOrders: (
      { blUsers, orders, info, searchString },
      getters,
      rootGetters
    ) => {
      let topregion = 'en-gb'
      let buyer = ''

      let formattedOrders = orders.map((order) => {
        let orderProducts = order.products.map((product) => {
          // формируем ядро закзаа
          let orderDetails = `${product.name} (${product.option
            .filter(({ name}) => name !== 'EventId')
            .map(({ value }) => value)
            .join(', ')})`

          let regionOpt = product.option.find(({ name }) => name === 'Region')
          const region = regionOpt ? regionOpt.value : ''
          topregion = region === 'EU' ? 'en-gb' : 'en-us'

          let gameMatches = product.model.match(/^([wd0-9]{2}).+$/i)
          let game = ''

          if (gameMatches && gameMatches.length > 1) {
            switch (gameMatches[1]) {
              case 'w0':
                game = `WoW DF`
                break
              case 'w1':
                game = `WoW`
                break
              case 'w2':
                game = `WoW BC`
                break
              case 'w3':
                game = `Destiny`
                break
              case 'w4':
                game = 'Diablo 4'
                break
            }
          }

          // получаем значения кастомных полей (battle tag and faction)
          let customFields = ''
          let customFieldsObj = {}

          for (let key in order.custom_field) {
            let custom_field = getters.customFieldById(
              key,
              order.custom_field[key]
            )
            customFields +=
              custom_field.value && key < 3
                ? `${custom_field.name}: ${custom_field.value}, `
                : ''
            customFieldsObj[custom_field.name] = custom_field.value
          }

          let buyerInfo = `Nick: ${order.firstname}, Server: ${order.lastname}, ${customFields}`
          buyer = customFieldsObj['Battle Tag'] || customFieldsObj['Bungie ID']

          let booster = rootGetters.booster.boosters.find(
            (el) => el.id === product.boosterId
          )
          let boosterInfo = ''

          if (booster) {
            boosterInfo = `Booster: ${booster.name}`
          }

          let eventTime = product.event_time
            ? `${fromDB(product.event_time, DATETIME_FORMAT)} MSK`
            : ''
          // уникальный id заказа
          const orderDetailsFull = `${game} ${region} ${orderDetails} Quantity: ${product.quantity}, ${buyerInfo} ${boosterInfo} ${eventTime} ${product.external_id}`

          return {
            id: product.order_product_id,
            game,
            region,
            eventTime,
            quantity: product.quantity,
            ccc: rootGetters.hmb.cccs.find(
              (el) => el.jobId === product.external_id
            ),
            text: highlight(orderDetailsFull, searchString),
            details: orderDetailsFull,
            refunded: product.refunded,
            completed: product.completed,
            booster: product.boosterId,
            total: product.total,
            currency: product.currency_code,
            externalId: highlight(product.external_id, searchString),
          }
        })

        // из апи статус возвращается строкой.
        let status = info.order_statuses.find(
          (el) => el.order_status_id == order.order_status_id
        )

        const server = order.lastname
          .replace('US', '')
          .replace('EU', '')
          .replace(/\(.+?\)/g, '')
          .replace(/(\w+)\s(\w+)/g, '$1-$2')
          .replace(/[^\w-]/g, '')

        return {
          id: order.order_id,
          status: status ? status.name : order.order_status_id,
          telephone: order.telephone,
          email: order.email,
          buyer: highlight(buyer, searchString),
          datetime: fromDB(order.date_added, DATETIME_FORMAT),
          orderInfo: '',
          products: orderProducts,
          booster: '',
          comment: order.comment,
          coupons: order.coupons,
          currency: order.currency_code,
          payment_gate: order.payment_code,
          inBlacklist: blUsers.find(
            (item) =>
              item.characterName == order.firstname ||
              item.email == order.email ||
              item.telephone == order.telephone
          ),
          armory: `https://worldofwarcraft.com/${topregion}/character/${server}/${order.firstname}`,
        }
      })

      return formattedOrders
    },
    params(state) {
      let params = {}
      for (let key in state.params) {
        if (state.params[key] !== '') {
          params[key] = state.params[key]
        }
      }
      return params
    },
    filterOptions(state) {
      return {
        page: state.params.page,
        itemsPerPage: state.params.limit,
      }
    },
    customFields({ customfields }) {
      let fields = []

      for (let key in customfields) {
        // выборка поля для языка по-умлочанию.
        let field = customfields[key].find((el) => el.language_id === '1')

        if (field) {
          let field_values = []

          for (let key in field.values) {
            field_values.push({
              text: field.values[key].name,
              value: field.values[key].custom_field_value_id,
            })
          }

          fields.push({
            id: field.custom_field_id,
            name: field.name,
            type: field.type,
            values: field_values,
          })
        }
      }
      return fields
    },
    customFieldById(state, getters) {
      return function (id, valueId) {
        let field = getters.customFields.find((el) => el.id == id)

        if (field) {
          if (field.values.length) {
            let valueName = field.values.find((el) => el.value == valueId)

            valueId = valueName ? valueName.text : valueId
          }

          return {
            name: field.name,
            value: valueId,
          }
        } else {
          return null
        }
      }
    },
  },
  mutations: {
    [SET_SEARCH_STRING]: (state, search) => {
      state.searchString = search
    },
    [SET_ORDERS]: (state, orders) => {
      state.orders = orders
    },
    [SET_TOTAL_ORDERS]: (state, count) => {
      state.total = parseInt(count)
    },
    [SET_STORE_INFO]: (state, info) => {
      state.info = info
    },
    [SET_ORDER]: (state, order) => {
      state.order = order
    },
    [SET_FILTER_DATE_FROM]: ({ params }, data) => {
      params.filter_date_added_from = data
    },
    [SET_FILTER_DATE_TO]: ({ params }, data) => {
      params.filter_date_added_to = data
    },
    [SET_FILTER_OPTIONS]: ({ params }, options) => {
      params.page = options.page
      params.limit = options.itemsPerPage
    },
    [SET_FILTER_STATUS]: ({ params }, data) => {
      params.filter_order_status_id = data
    },
    [SET_FILTER_OPTIONS_PAGE]: ({ params }, page) => {
      params.page = page
    },
    [SET_BLACKLIST_USERS]: (state, data) => {
      state.blUsers = state.blUsers.concat(data)
    },
    [SET_NEW_ORDERS]: (state, data) => {
      let newOrders = []
      data.forEach((item) => {
        if (
          state.orders.find((order) => order.order_id == item.order_id) ===
          undefined
        ) {
          newOrders.push(item)
        }
      })
      state.orders = newOrders.concat(state.orders)
    },
    [SET_CUSTOM_FIELDS]: (state, data) => {
      state.customfields = data
    },
    [SET_ORDER_ITEM]: (state, payload) => {
      const { item, itemId, orderId } = payload

      let orderIndex = state.orders.findIndex((el) => el.order_id == orderId)
      // нашли заказ
      if (orderIndex > -1) {
        let orderItemIndex = state.orders[orderIndex].products.findIndex(
          (el) => el.order_product_id == itemId
        )
        // нашли в нём строчку
        if (orderItemIndex > -1) {
          for (let key in item) {
            state.orders[orderIndex].products[orderItemIndex][key] = item[key]
          }
        }
      }
    },
    [UPDATE_ORDER_STATUS]: (state, payload) => {
      const orderIndex = state.orders.findIndex(
        ({ order_id }) => order_id === payload.order_id
      )
      if (orderIndex > -1) {
        state.orders[orderIndex].order_status_id = payload.status
      }
    },
    [SET_LOADING]: (state, payload) => {
      state.loading = payload
    },
  },
  actions: {
    setSearchString: ({ commit }, payload) => {
      commit(SET_SEARCH_STRING, payload)
    },
    updateOrderItem: ({ commit, dispatch, getters }, payload) => {
      const { item, itemId } = payload
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        opencart
          .put(`${API_ORDER_ITEMS}/${itemId}`, item)
          .then((resp) => {
            commit(SET_ORDER_ITEM, payload)

            const order = getters.orders.find(
              ({ order_id }) => order_id === payload.orderId
            )
            if (order) {
              const completedJobs = order.products.filter(
                ({ completed }) => completed === false
              )
              const orderStatus = getters.orderStatuses.find(
                ({ text }) => text === 'Completed'
              )

              if (orderStatus && completedJobs.length === 0) {
                const orderData = {
                  order_id: payload.orderId,
                  status: orderStatus.value,
                }
                dispatch('updateOrder', orderData).then(() => {
                  commit(UPDATE_ORDER_STATUS, orderData)
                  commit(SET_LOADING, false)
                })
              }
            }

            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            commit(SET_LOADING, false)
          })
      })
    },
    getCustomFields: ({ commit }) => {
      return new Promise((resolve, reject) => {
        opencart
          .get(`${API_CUSTOM_FIELDS}/limit/30/page/1`)
          .then((resp) => {
            commit(SET_CUSTOM_FIELDS, resp.data.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getNewOrders: ({ commit, dispatch }) => {
      let params = {
        filter_date_added_from: moment().format('YYYY-MM-DD'),
        filter_order_status_id: '17,13,19',
      }

      return new Promise((resolve, reject) => {
        opencart
          .get(API_ORDERS_DETAIL, { params: params })
          .then((resp) => {
            commit(SET_NEW_ORDERS, resp.data.data)
            if (resp.data.data.length) dispatch('getBlUsers', resp.data.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    searchOrders: ({ commit, dispatch }, searchString) => {
      commit(SET_LOADING, true)
      const initialSearch = searchString
      if (/&[A-Z0-9]{6}/i.test(searchString)) {
        searchString = searchString.replaceAll('&', '').replaceAll(/\s+/g, '|')
      }

      let search = { search: searchString }

      return new Promise((resolve, reject) => {
        opencart
          .post(API_ORDER_SEARCH + '?limit=100', search)
          .then((resp) => {
            commit(SET_SEARCH_STRING, initialSearch)
            commit(SET_ORDERS, resp.data.data)
            if (resp.data.data.length) dispatch('getBlUsers', resp.data.data)
            commit(SET_LOADING, false)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getBlUsers: ({ commit }, data) => {
      if (!data.length) return

      let search = { search: [] }

      data.forEach((item) => {
        search.search.push(item.email)
        search.search.push(item.telephone)
        search.search.push(item.firstname)
      })

      return new Promise((resolve, reject) => {
        api
          .post(API_BL_SEARCH, search)
          .then((resp) => {
            commit(SET_BLACKLIST_USERS, resp.data.users)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setFilterStatus: ({ commit }, id) => {
      commit(SET_FILTER_STATUS, id)
    },
    setFilterDates: ({ commit }, dates) => {
      commit(SET_FILTER_DATE_FROM, dates[0])
      commit(SET_FILTER_DATE_TO, dates[1])
    },
    setFilterOptions: ({ commit }, options) => {
      commit(SET_FILTER_OPTIONS, options)
    },
    resetFilterOptions: ({ commit }) => {
      commit(SET_FILTER_OPTIONS_PAGE, 1)
    },
    resetFilterDates: ({ commit }) => {
      commit(SET_FILTER_DATE_FROM, '')
      commit(SET_FILTER_DATE_TO, '')
    },
    resetFilterStatus: ({ commit }) => {
      commit(SET_FILTER_STATUS, '')
    },
    getOrder: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        opencart
          .get(`${API_ORDERS}/${id}`)
          .then((resp) => {
            commit(SET_ORDER, resp.data.data)
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
    updateOrder: ({ commit }, order) => {
      return new Promise((resolve, reject) => {
        opencart
          .put(`${API_ORDERS}/${order.order_id}`, order)
          .then((resp) => {
            commit(SET_ORDER, resp.data.data)
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
    getOrders: ({ commit, dispatch, getters }) => {
      commit(SET_LOADING, true)
      let params = getters.params
      return new Promise((resolve, reject) => {
        opencart
          .get(API_ORDERS_DETAIL, { params: params })
          .then((resp) => {
            commit(SET_SEARCH_STRING, '')
            commit(SET_ORDERS, resp.data.data)
            commit(SET_TOTAL_ORDERS, resp.headers['x-total-count'])
            dispatch('getBlUsers', resp.data.data)
            commit(SET_LOADING, false)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getStoreInfo: ({ commit }) => {
      return new Promise((resolve, reject) => {
        opencart
          .get(API_INIT)
          .then((resp) => {
            commit(SET_STORE_INFO, resp.data.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
